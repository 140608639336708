import React from 'react';
import { collect } from 'react-recollect';
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import classnames from 'classnames'

const PhoneNumberInput = ({ store, phoneNumberError, isValidPhoneNumber, handlePhoneInputChange, phoneInputCountry, updatePhoneInputCountry, value }) => {

  const allowedCountriesForJanzcorp = ['US', 'GB', 'DE', 'IT', 'ES', 'KR', 'JP', 'GU', 'PR']
  const allowedCountriesForVivisol = ['IT', 'GB', 'NL', 'US']

  const countries = store.currentSubdomain.customerSubdomain === 'janzcorp' ?
    allowedCountriesForJanzcorp :
    store.currentSubdomain.customerSubdomain === 'vivisol' ?
      allowedCountriesForVivisol :
      !store.currentUser.isStaff ?
        ['US'] : undefined
  // undefined means we do not specify countries which means allow all (for staff).

  return (
    <div className='d-flex align-items-center gap-1'>
      <div className='text-secondary' >+{getCountryCallingCode(phoneInputCountry)}</div>
      <PhoneInput
        className={classnames('w-100', {
          success: !phoneNumberError && isValidPhoneNumber && !store.twilioLookupLoading,
          error: phoneNumberError && !store.twilioLookupLoading,
          twilioLookupLoading: store.twilioLookupLoading
        })}
        placeholder='Enter phone number'
        defaultCountry={phoneInputCountry}
        value={value}
        countries={countries}
        international={false}
        addInternationalOption={false}
        onCountryChange={(country) => updatePhoneInputCountry(country)}
        onChange={handlePhoneInputChange}
      />
    </div>
  )
}

export default collect(PhoneNumberInput)