import React, { useEffect, useState } from "react";
import { languageOptions } from '../language';
import { getLangType, isValidArray } from '../utils/utils';
import { collect } from "react-recollect";
import { Row, Col, Input, Button as ButtonRS, Alert } from 'reactstrap';
import Select from 'react-select';
import { isEqual } from 'lodash';
import { appStore } from "../store/store";
import PressureInput from "./PressureInput";
import { toast } from 'react-toastify';

const langType = getLangType();

const createDropdownOptions = (type, name) => {
  switch (type) {
    case 'yesNo':
      return yesNoQuestionOptions.map(opt => ({ name, type, ...opt }))
    case 'gender':
      return genderQuestionOptions.map(opt => ({ name, type, ...opt }))
    case 'sleep_position':
      return sleepPositionsOptions.map(opt => ({ name, type, ...opt }))
    case 'beard_type':
      return beardTypeQuestionOptions.map(opt => ({ name, type, ...opt }))
  }
}

const genderQuestionOptions = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }]
const yesNoQuestionOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
const sleepPositionsOptions = [
  { label: 'Side', value: 'side' },
  { label: 'Back', value: 'back' },
  { label: 'Stomach', value: 'stomach' },
  { label: 'Sitting Up', value: 'sitting_up' },
]
const beardTypeQuestionOptions = [
  { label: 'Mustache', value: 'mustache' },
  { label: 'Circle', value: 'circle' },
  { label: 'Stubble', value: 'stubble' },
  { label: 'Full Beard', value: 'full_beard' },
]

const PatientQuestionnaire = ({ store, renderMagneticImplantList, ptSleepPositions, setRecommendedMask }) => {
  const { selectedRow, selectedRow: { patient_attributes } } = store
  const [isEditing, setIsEditing] = useState(false)
  const [patientAttributes, setPatientAttributes] = useState(patient_attributes)
  const [selectedSleepPositions, setSelectedSleepPositions] = useState(patient_attributes.sleep_position)
  const [errors, setErrors] = useState({})
  const [pressureOptions, setPressureOptions] = useState([])

  // Since we are using a global state as a value of this local state, they need to be synced to keep sync
  useEffect(() => {
    setPatientAttributes(patient_attributes);
  }, [patient_attributes]);

  const onDropdownChange = (e) => {
    // Handle nested logics
    if (e.name === 'no_teeth') {
      if (e.value === false) {
        setPatientAttributes((prev) => ({ ...prev, [e.name]: e.value, sleep_in_dentures: null }))
      } else {
        setPatientAttributes((prev) => ({ ...prev, [e.name]: e.value, missing_teeth: null }))
      }
    } else if (e.name === 'mild_mouth_open' && e.value === false) {
      setPatientAttributes((prev) => ({ ...prev, [e.name]: e.value, mouth_open: false }))
    } else if (e.name === 'mild_nasal_congestion') {
      setPatientAttributes((prev) => ({ ...prev, [e.name]: e.value, nasal_congestion: false }))
    } else if (e.name === 'nasal_congestion' && e.value === true) {
      setPatientAttributes((prev) => ({ ...prev, [e.name]: e.value, mild_nasal_congestion: true }))
    } else if (e.name === 'beard') {
      setPatientAttributes((prev) => ({ ...prev, [e.name]: e.value, beard_type: e.value && patientAttributes.gender === 'male' ? 'mustache' : null }))
    } else if (e.name === 'gender' && e.value === 'female') {
      setPatientAttributes((prev) => ({ ...prev, [e.name]: e.value, beard_type: null }))
    }
    else setPatientAttributes((prev) => ({ ...prev, [e.name]: e.value }))
  }

  const onSleepPositionDropdownChange = (e) => {
    const newOptions = []
    if (e && e.length > 0) {
      e.forEach(opt => newOptions.push(opt.value))
      if (errors?.sleep_position) setErrors((prev) => ({ ...prev, sleep_position: null }))
    }
    updateSleepPositions(newOptions)
    if (!e || e.length < 1) {
      setErrors((prev) => ({ ...prev, sleep_position: `Required field!` }))
    }
  }

  const updateSleepPositions = (positionsArr) => {
    const newSleepPositions = { ...selectedSleepPositions }
    for (const key in newSleepPositions) {
      newSleepPositions[key] = positionsArr.includes(key)
    }
    setSelectedSleepPositions(newSleepPositions)
    setPatientAttributes((prev) => ({ ...prev, sleep_position: { ...newSleepPositions } }))
  }

  const getDropdownValue = (attr) => {
    if (attr === 'gender') {
      return genderQuestionOptions.filter(opt => opt.value === patientAttributes[attr])
    } else if (attr === 'beard_type'){
      return beardTypeQuestionOptions.filter(opt => opt.value === patientAttributes[attr])
    } else if (attr === 'sleep_position') {
      return sleepPositionsOptions.filter(opt => {
        const selectedSleepPositionsArray = Object.keys(selectedSleepPositions)
          .filter(key => !!selectedSleepPositions[key]);
        return selectedSleepPositionsArray.includes(opt.value)
      })
    }
    return yesNoQuestionOptions.filter(opt => opt.value === !!patientAttributes[attr])
  }

  const handleInputChange = (e) => {
    e.persist()
    const { name, value } = e.target
    const minVal = name === 'height_ft' ? 1 : 'height_in' ? 0 : 40
    const maxVal = name === 'height_ft' ? 7 : 'height_in' ? 11 : 1400

    if (name === 'height_ft') {
      setPatientAttributes((prev) => ({ ...prev, height_ft: value ? Number(value) : '' }))
      if (Number(value) < minVal || Number(value) > maxVal) {
        setErrors((prev) => ({ ...prev, height_ft: `Please enter a valid input for height (ft)` }))
      } else setErrors((prev) => ({ ...prev, height_ft: null }))
    } else if (name === 'height_in') {
      setPatientAttributes((prev) => ({ ...prev, height_in: value ? Number(value) : '' }))
      if (Number(value) < minVal || Number(value) > maxVal) {
        setErrors((prev) => ({ ...prev, height_in: `Please enter a valid input for height (in)` }))
      } else setErrors((prev) => ({ ...prev, height_in: null }))
    } else if (name === 'weight') {
      setPatientAttributes((prev) => ({ ...prev, weight: value ? Number(value) : '' }))
      if (Number(value) < 40 || Number(value) > 1400) {
        setErrors((prev) => ({ ...prev, weight: `Please enter a valid value.` }))
      } else setErrors((prev) => ({ ...prev, weight: null }))
    }
  }

  const handleSaveEdit = async () => {
    // If there is no error and there IS an update, re-process pt and quit editing
    if (!isErrorsExist()) {
      if (!hasAttributesChanged()) setIsEditing(false)
      if (hasAttributesChanged()) {
        // Handle pap therapy updates first (pap_therapy, pressure on invite_links)
        const papTherapyUpdates = ['pap_therapy', 'pressure']
        if ( papTherapyUpdates.some(k => patientAttributes[k] !== patient_attributes[k])) {
          appStore.editData(
            {
              pressure: patientAttributes.pressure,
              pap_therapy: patientAttributes.pap_therapy,
              patient_subdomain: store.selectedRow.subdomain,
              website_token: store.selectedRow.website_token,
            },
            'pressure',
            'pap_therapy',
          );
        }
        // Re-process patient
        const data = {website_token: selectedRow.website_token, patientAttributes}
        data.patientAttributes.height = (patientAttributes.height_ft * 12) + (patientAttributes.height_in)
        await appStore.reprocessPatient(data)
        toast.success('Questionnaire edited and patient re-processed successfully.')
        setPatientAttributes(store.selectedRow.patient_attributes)

        // We set recommended mask to trigger re-render and see new results
        setRecommendedMask(store.selectedRow.model_output.mask_results[0])
        setIsEditing(false)
      }
    }
  }

  const isErrorsExist = () => Object.values(errors).filter(er => !!er).length

  const hasAttributesChanged = () => {
    const prevAttributes = patient_attributes
    const updatedAttributes = patientAttributes
    return !isEqual(prevAttributes, updatedAttributes)
  }

  const resetPatientAttributes = () => setPatientAttributes(patient_attributes)
  
  const createPressureOptions = (papTherapy) => {
    if (papTherapy === 'AutoPAP') {
      setPressureOptions([
        { value: '<16', label: 'Auto with max pressure <16', name: 'pressure' },
        { value: '>=16', label: 'Auto with max pressure >=16', name: 'pressure' }
      ])
    } else {
      const options = []
      for (let i = 5; i <= 19; i++) {
        options.push({ value: i.toString(), label: i.toString(), name: 'pressure' })
      }
      options.push({ value: '> 20', label: '> 20', name: 'pressure' })
      setPressureOptions(options)
    }
  }

  const getPapTherapy = (value) => {
    setPatientAttributes((prev) => ({ ...prev, pap_therapy: value, pressure: null }))
    // Set selected pressure back to null to force user to choose from new options
  }

  useEffect(() => {
    if (patientAttributes.pap_therapy && !patientAttributes.pressure) {
      setErrors((prev) => ({ ...prev, pressure: 'Please select pressure!' }))
    }
    createPressureOptions(patientAttributes.pap_therapy)
  }, [patientAttributes.pap_therapy])

  useEffect(() => {
    if (patientAttributes.pressure && errors.pressure) {
      setErrors((prev) => ({ ...prev, pressure: null }))
    }
  }, [patientAttributes.pressure])

  return <div>
    {!['readonly', 'tech'].includes(store.currentUser.userRole) &&
      !isEditing && (
        <div className="d-flex justify-content-end mb-2">
          <span
            className='details-padding mx-0 py-1'
            onClick={() => setIsEditing(!isEditing)}>
            <i className='fas fa-edit' />
            {languageOptions.edit[langType]}
          </span>
        </div>
      )}
    {isEditing && (
      <Alert color='primary'>Editing questionnaire will update the recommendation list.</Alert>
    )}
    {(patientAttributes && selectedRow.model_output) && (
      <Row style={{ marginBottom: '20px' }}>
        <Col xl='6'>

          <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
            <Col lg='7'>
              <strong>{languageOptions.ptGender[langType]}</strong>
            </Col>
            <Col lg='5'>
              {isEditing ? (
                <Select
                  value={getDropdownValue('gender')}
                  options={createDropdownOptions('gender', 'gender')}
                  onChange={onDropdownChange}
                />
              ) : (
                <span style={{ textTransform: "capitalize" }}>{
                  patientAttributes.gender
                }</span>
              )}
            </Col>
          </Row>

          <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
            <Col lg='7'><strong>Height: </strong></Col>
            <Col lg='5'>
              {isEditing ? (
                <>
                  <div className='numberInput__container'>
                    <Input
                      invalid={Boolean(errors.height_ft)}
                      className="numberInput"
                      type="number"
                      name="height_ft"
                      min="1"
                      max="9"
                      step="1"
                      placeholder="ft"
                      value={patientAttributes.height_ft}
                      onChange={handleInputChange}
                    />
                    <Input
                      invalid={Boolean(errors.height_in)}
                      className="numberInput"
                      type="number"
                      name="height_in"
                      min="0"
                      max="11"
                      step="1"
                      placeholder="in"
                      value={patientAttributes.height_in}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <small style={{ fontSize: '10px', color: 'var(--bs-danger)', display: 'block' }}>{errors?.height_ft}</small>
                    <small style={{ fontSize: '10px', color: 'var(--bs-danger)', display: 'block' }}>{errors?.height_in}</small>
                  </div>
                </>
              ) : (
                !!patientAttributes.height_ft && (
                  <span>
                    {patientAttributes.height_ft}' {patientAttributes.height_in}"
                  </span>)
              )}
            </Col>
          </Row>

          <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
            <Col lg='7'><strong>Weight (lbs):</strong></Col>
            <Col lg='5'>
              {isEditing ? (
                <>
                  <Input
                    invalid={Boolean(errors.weight)}
                    className="numberInput"
                    type="number"
                    name="weight"
                    min={40}
                    max={1400}
                    step="1"
                    placeholder="Weight"
                    value={patientAttributes.weight}
                    onChange={handleInputChange}
                  />
                  <div>
                    <small style={{ fontSize: '10px', color: 'var(--bs-danger)', display: 'block' }}>{errors?.weight}</small>
                  </div>
                </>
              ) : (
                <span>{patientAttributes.weight}</span>
              )}
            </Col>
          </Row>

          {!!patientAttributes.bmi && (
            <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
              <Col lg='7'><strong>BMI (calculated):</strong></Col>
              <Col lg='5'>{patientAttributes.bmi}</Col>
            </Row>
          )}

          <QuestionnaireDropdownField
            label={languageOptions.ptWearDentures[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'no_teeth')}
            selectedValue={getDropdownValue('no_teeth')}
            onDropdownChange={onDropdownChange}
          /> {/* no_teeth is true only when answer is wear dentures*/}
          {patientAttributes.no_teeth ?
            (
              <QuestionnaireDropdownField
                label={languageOptions.ptSleepDentures[langType]}
                isEditing={isEditing}
                isDisabled={!patientAttributes.no_teeth}
                options={createDropdownOptions('yesNo', 'sleep_in_dentures')}
                selectedValue={getDropdownValue('sleep_in_dentures')}
                onDropdownChange={onDropdownChange}
              />
            ) :
            (
              <QuestionnaireDropdownField
                label={languageOptions.ptMissingTeeth[langType]}
                isEditing={isEditing}
                options={createDropdownOptions('yesNo', 'missing_teeth')}
                selectedValue={getDropdownValue('missing_teeth')}
                onDropdownChange={onDropdownChange}
              />
            )
          }
          {isValidArray(ptSleepPositions) && (
            <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
              <Col lg='7'>
                <strong>{languageOptions.sleepPosition[langType]}</strong>
              </Col>
              <Col lg='5'>
                {isEditing ? (
                  <Select
                    isMulti={true}
                    closeMenuOnSelect={false}
                    value={getDropdownValue('sleep_position')}
                    options={createDropdownOptions('sleep_position', 'sleep_position')}
                    onChange={onSleepPositionDropdownChange}
                  />
                ) : (
                  <span style={{ textTransform: 'capitalize' }}>{ptSleepPositions.join(', ')}</span>
                )}
                {errors?.sleep_position && (
                  <small style={{ fontSize: '12px', color: 'var(--bs-danger)', display: 'block' }}>{errors?.sleep_position}</small>
                )}
              </Col>
            </Row>
          )}
          
          <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
            <Col lg='7'><strong>PAP Therapy (user entry):</strong></Col>
            <Col lg='5'>
              {isEditing ? (
                <>
                  <PressureInput getPapTherapy={getPapTherapy} />
                  <div>
                    <small style={{ fontSize: '12px', color: 'var(--bs-danger)', display: 'block' }}>{errors?.pressure}</small>
                  </div>
                </>
              ) : (
                <span>
                  {patientAttributes.pap_therapy}
                </span>
              )}
            </Col>
          </Row>

          <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
            <Col lg='7'><strong>Pressure (user entry):</strong></Col>
            <Col lg='5'>
              {isEditing ? (
                <Select
                  placeholder={languageOptions.selectPressure[langType]}
                  options={pressureOptions}
                  onChange={onDropdownChange}
                  value={
                    patientAttributes.pressure ?
                      { value: patientAttributes.pressure, label: patientAttributes.pressure }
                      : null
                  }
                />
              ) : (
                <span>{patientAttributes.pressure}</span>
              )}
            </Col>
          </Row>
        </Col>

        <Col xl='6'>
          <QuestionnaireDropdownField
            label={languageOptions.ptMouthBreathing[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'mild_mouth_open')}
            selectedValue={getDropdownValue('mild_mouth_open')}
            onDropdownChange={onDropdownChange}
          />
          <QuestionnaireDropdownField
            label='Often Wake Up With Dry Mouth:'
            isEditing={isEditing}
            isDisabled={!patientAttributes.mild_mouth_open}
            options={createDropdownOptions('yesNo', 'mouth_open')}
            selectedValue={getDropdownValue('mouth_open')}
            onDropdownChange={onDropdownChange}
          />
          <QuestionnaireDropdownField
            label={languageOptions.ptNasalCongestion[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'mild_nasal_congestion')}
            selectedValue={getDropdownValue('mild_nasal_congestion')}
            onDropdownChange={onDropdownChange}
          />
          <QuestionnaireDropdownField
            label={languageOptions.ptSevereNasalCongestion[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'nasal_congestion')}
            selectedValue={getDropdownValue('nasal_congestion')}
            onDropdownChange={onDropdownChange}
          />
          <QuestionnaireDropdownField
            label={languageOptions.ptClaustrophobic[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'claustrophobic')}
            selectedValue={getDropdownValue('claustrophobic')}
            onDropdownChange={onDropdownChange}
          />
          <QuestionnaireDropdownField
            label={languageOptions.facialHair[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'beard')}
            selectedValue={getDropdownValue('beard')}
            onDropdownChange={onDropdownChange}
          />
          { patientAttributes.beard && patientAttributes.gender === 'male' && (
            <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
              <Col lg='7'><strong>Beard type:</strong></Col>
              <Col lg='5' className="d-flex">
                <div style={{ width: '100%' }}>
                  {isEditing ? (
                    <Select
                      options={createDropdownOptions('beard_type', 'beard_type')}
                      value={getDropdownValue('beard_type')}
                      onChange={onDropdownChange}
                    />
                  ) : (
                    <span style={{ textTransform: "capitalize" }}>{patientAttributes.beard_type}</span>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <QuestionnaireDropdownField
            label={languageOptions.ptMagneticImplant[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'magnet_sensitive_implants')}
            selectedValue={getDropdownValue('magnet_sensitive_implants')}
            onDropdownChange={onDropdownChange}
            colored={true}
          />
          <QuestionnaireDropdownField
            label={languageOptions.ptFacialSkinIrritation[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'facial_skin_irritation')}
            selectedValue={getDropdownValue('facial_skin_irritation')}
            onDropdownChange={onDropdownChange}
          />
          <QuestionnaireDropdownField
            label={languageOptions.ptDeviatedSeptum[langType]}
            isEditing={isEditing}
            options={createDropdownOptions('yesNo', 'deviated_septum')}
            selectedValue={getDropdownValue('deviated_septum')}
            onDropdownChange={onDropdownChange}
          />
        </Col>
        {isEditing && (
          <Col className='d-flex justify-content-end mt-2 gap-2'>
            <ButtonRS
              style={{ width: '4rem' }}
              color='primary'
              size='sm'
              onClick={handleSaveEdit}
              disabled={store.reprocessPatientLoading}
            >
              {store.reprocessPatientLoading ?
                <div className='spinner-border spinner-border-sm text-white ' role='status'>
                </div> :
                <span>{languageOptions.save[langType]}</span>
              }
            </ButtonRS>
            <ButtonRS
              color='danger'
              size='sm'
              onClick={() => {
                setIsEditing(false)
                resetPatientAttributes()
              }}
            >
              {languageOptions.cancel[langType]}
            </ButtonRS>
          </Col>
        )}
        {renderMagneticImplantList(patientAttributes?.magnet_sensitive_implants)}
      </Row>
    ) || (
        <Row>
          <Col>
            {languageOptions.ptNoResponse[langType]}
          </Col>
        </Row>
      )
    }
  </div>;
};

const QuestionnaireDropdownField = ({ label, isEditing, isDisabled, options, selectedValue, onDropdownChange, colored=false}) => {
  let fieldColor = colored && selectedValue[0].value ? 'darkred' : 'black';
  return (
    <Row className={`d-flex align-items-center mb-${isEditing ? '2' : '1'}`}>
      <Col lg='7'><strong style={{color: fieldColor}}>{label}</strong></Col>
      <Col lg='5' className="d-flex">
        <div style={{ width: '100%' }}>
          {isEditing ? (
            <Select
              isDisabled={isDisabled}
              options={options}
              value={selectedValue}
              onChange={onDropdownChange}
            />
          ) : (
            <span style={{color: fieldColor}}>{selectedValue[0].value ? "Yes" : "No"}</span>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default collect(PatientQuestionnaire);
